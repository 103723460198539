import { MANAGE_SUBSCRIPTION_ROUTE, ORGANIZATION_ROUTE } from '@kindo/universal';
/** Top-level Settings Tabs */
export enum SettingsTab {
  API = 'API',
  INTEGRATIONS = 'INTEGRATIONS',
  MANAGE_SUBSCRIPTION = 'MANAGE_SUBSCRIPTION',
  MODELS = 'MODELS',
  ORG = 'ORG',
  PLUGINS = 'PLUGINS',
  SECURITY = 'SECURITY',
}
export const SETTINGS_TAB_DISPLAY_NAMES: Record<SettingsTab, string> = {
  [SettingsTab.API]: 'API',
  [SettingsTab.MODELS]: 'Models',
  [SettingsTab.ORG]: 'Organization',
  [SettingsTab.MANAGE_SUBSCRIPTION]: 'Manage Plan',
  [SettingsTab.INTEGRATIONS]: 'Integrations',
  [SettingsTab.PLUGINS]: 'Plugins',
  [SettingsTab.SECURITY]: 'Security'
};
export const SETTINGS_TAB_ROUTES: Record<SettingsTab, string> = {
  [SettingsTab.API]: 'api',
  [SettingsTab.MODELS]: 'models',
  [SettingsTab.ORG]: ORGANIZATION_ROUTE,
  [SettingsTab.MANAGE_SUBSCRIPTION]: MANAGE_SUBSCRIPTION_ROUTE,
  [SettingsTab.INTEGRATIONS]: 'integrations',
  [SettingsTab.PLUGINS]: 'plugins',
  [SettingsTab.SECURITY]: 'security'
};