import { SupportedLlm, LLM_DISPLAY_NAMES, FeatureFlags, FEATURE_FLAG_DISPLAYED_CHAT_LLMS, insertIf, ModelCategory, isModelCategory, isGeneralPurposeLlm, isLongContextLlm, isReasoningLlm, isCybersecurityFocusedLlm, isMultimodalLlm } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs/client';
import { useState } from 'react';
import styled from 'styled-components';
import { MODEL_CATEGORY_TO_MODELS, MODEL_CATEGORY_TITLES, MODEL_CATEGORY_DESCRIPTIONS } from './ModelSelectionModal.consts';
import { Button, TypographyWeight, TypographySize, Typography, TypographyFont, TypographyCasing, ModalWidth, ButtonType, Modal, TextIconColor, Select, Icons, Icon } from '~/components/core';
import { withLink } from '~/components/core/hocs';
import { MODEL_COST_TIERS, COST_TIER_INDICATOR_TEXT } from '~/components/Settings/ModelSettings/ModelChoice/ModelChoice.consts';
import { SETTINGS_TAB_ROUTES, SettingsTab } from '~/components/Settings/SettingsScreen.consts';
import { LocalStorageKey } from '~/constants/localStorage.consts';
import useLocalStorage from '~/hooks/useLocalStorage';
import { nextTrpc } from '~/trpc';

// ModelCategoryCard styles and types
const CategoryCardContainer = styled.div<{
  isSelected: boolean;
}>(({
  isSelected
}) => [
// Base styles
{
  "display": "flex",
  "width": "100%",
  "cursor": "pointer",
  "flexDirection": "column",
  "justifyContent": "space-between",
  "gap": "1rem",
  "borderRadius": "0.25rem",
  "borderWidth": "2px",
  "borderColor": "transparent",
  "padding": "1rem",
  "textAlign": "left",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "200ms"
}, isSelected ? {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(52 45 137 / var(--tw-bg-opacity))",
  "&:hover": {
    "borderColor": "#25224575"
  }
} : {
  "backgroundColor": "#25224575",
  "&:hover": {
    "--tw-border-opacity": "1",
    "borderColor": "rgb(52 45 137 / var(--tw-border-opacity))"
  }
}]);
const TitleAndDescription = styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem"
});
const TitleContainer = styled.div({
  "marginBottom": "1.5rem",
  "display": "flex",
  "flexDirection": "column"
});
// On mobile, # of columns is two, on desktop it's three
const CategoryContainer = styled.div({
  "marginBottom": "2rem",
  "display": "grid",
  "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
  "gap": "1rem",
  "overflowY": "scroll",
  "@media (min-width: 768px)": {
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))"
  }
});
const BottomContainer = styled.div({
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "space-between",
  "@media (min-width: 768px)": {
    "flexDirection": "row"
  }
});
const SelectWrapper = styled.div`
  pointer-events: none;
  > * {
    pointer-events: auto;
  }
`;
const LinkContainer = styled.div({
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.5rem"
});
interface ModelSelectionModalProps {
  chatModel: SupportedLlm;
  onChange: (model: SupportedLlm) => void;
  placeholderLabel: string;
  prefix: string;
}
export const ModelSelectionModal = ({
  onChange,
  chatModel,
  prefix,
  placeholderLabel
}: ModelSelectionModalProps) => {
  // Custom Hooks
  const [selectedModelCategory, setSelectedModelCategory] = useLocalStorage(LocalStorageKey.MODEL_CATEGORY);
  const [selectedGeneralPurposeModel, setSelectedGeneralPurposeModel] = useLocalStorage(LocalStorageKey.GENERAL_PURPOSE_MODEL);
  const [selectedLongContextModel, setSelectedLongContextModel] = useLocalStorage(LocalStorageKey.LONG_CONTEXT_MODEL);
  const [selectedReasoningModel, setSelectedReasoningModel] = useLocalStorage(LocalStorageKey.REASONING_MODEL);
  const [selectedCybersecurityModel, setSelectedCybersecurityModel] = useLocalStorage(LocalStorageKey.CYBERSECURITY_MODEL);
  const [selectedMultimodalModel, setSelectedMultimodalModel] = useLocalStorage(LocalStorageKey.MULTIMODAL_MODEL);

  // State
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ModelCategory | null>(selectedModelCategory);

  // Map from category to selected model in localStorage
  const CATEGORY_SELECTED_MODEL: Record<ModelCategory, SupportedLlm> = {
    [ModelCategory.GENERAL_PURPOSE]: selectedGeneralPurposeModel,
    [ModelCategory.LONG_CONTEXT]: selectedLongContextModel,
    [ModelCategory.REASONING]: selectedReasoningModel,
    [ModelCategory.CYBERSECURITY_FOCUSED]: selectedCybersecurityModel,
    [ModelCategory.MULTIMODAL]: selectedMultimodalModel
  };

  // Queries
  const {
    data: availableModels
  } = nextTrpc.adminSecurityConfig.listOrgAvailableModels.useQuery();

  // TODO: filter using a feature flag on the backend. Right now, Feature Flagged LLMs
  // will show on the selector, but not be usable if turned off in sec. settings
  const canAccessAllChatModels = useFlag(FeatureFlags.FEATURE_FLAGGED_LLMS);
  const orgAvailableModels: SupportedLlm[] = [...(availableModels ?? []), ...insertIf(canAccessAllChatModels, ...FEATURE_FLAG_DISPLAYED_CHAT_LLMS)];

  // Update the stored model for a category
  const updateStoredModelForCategory = (category: ModelCategory, model: SupportedLlm) => {
    switch (category) {
      case ModelCategory.GENERAL_PURPOSE:
        if (isGeneralPurposeLlm(model)) {
          setSelectedGeneralPurposeModel(model);
        }
        break;
      case ModelCategory.LONG_CONTEXT:
        if (isLongContextLlm(model)) {
          setSelectedLongContextModel(model);
        }
        break;
      case ModelCategory.REASONING:
        if (isReasoningLlm(model)) {
          setSelectedReasoningModel(model);
        }
        break;
      case ModelCategory.CYBERSECURITY_FOCUSED:
        if (isCybersecurityFocusedLlm(model)) {
          setSelectedCybersecurityModel(model);
        }
        break;
      case ModelCategory.MULTIMODAL:
        if (isMultimodalLlm(model)) {
          setSelectedMultimodalModel(model);
        }
        break;
      default:
        if (isGeneralPurposeLlm(model)) {
          setSelectedGeneralPurposeModel(model);
        }
    }
  };
  const handleModelSelection = ({
    model,
    category,
    isDirectCategorySelect
  }: {
    model: SupportedLlm;
    category?: ModelCategory;
    isDirectCategorySelect?: boolean;
  }) => {
    // Update the selected model first in all cases
    onChange(model);

    // Case 1: Direct category selection
    if (isDirectCategorySelect && category) {
      setSelectedCategory(category);
      setSelectedModelCategory(category);
      return;
    }

    // Case 2: Selection from "All Models" dropdown
    if (!category) {
      setSelectedCategory(null);
      return;
    }

    // Case 3: Selection from a category dropdown
    if (category && !isDirectCategorySelect) {
      setSelectedCategory(category);
      setSelectedModelCategory(category);
      updateStoredModelForCategory(category, model);
    }
  };
  return <>
      <Button label={prefix + LLM_DISPLAY_NAMES[chatModel] || placeholderLabel} onClick={() => setIsModalOpen(true)} type={ButtonType.OUTLINED} />

      <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen} width={ModalWidth.NINE_TWENTY}>
        <TitleContainer>
          <Typography size={TypographySize.LARGE} weight={TypographyWeight.MEDIUM}>
            Choose a Model
          </Typography>
          <Typography color={TextIconColor.SECONDARY} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
            Select a Kindo recommended preset or pick from all of our models
          </Typography>
        </TitleContainer>

        <CategoryContainer>
          {Object.entries(MODEL_CATEGORY_TO_MODELS).filter(([category]) => category !== ModelCategory.MULTIMODAL).map(([category, categoryModels]) => {
          if (!isModelCategory(category)) {
            return null;
          }
          return <CategoryCardContainer key={category} isSelected={selectedCategory === category} onClick={() => {
            handleModelSelection({
              model: CATEGORY_SELECTED_MODEL[category],
              category,
              isDirectCategorySelect: true
            });
          }}>
                  <TitleAndDescription>
                    <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.HEADING} size={TypographySize.MEDIUM} weight={TypographyWeight.MEDIUM}>
                      {MODEL_CATEGORY_TITLES[category]}
                    </Typography>

                    <Typography color={TextIconColor.SECONDARY} font={TypographyFont.PARAGRAPH} size={TypographySize.SMALL}>
                      {MODEL_CATEGORY_DESCRIPTIONS[category]}
                    </Typography>
                  </TitleAndDescription>
                  <SelectWrapper onClick={e => e.stopPropagation()} onKeyDown={e => e.stopPropagation()} role="button" tabIndex={0}>
                    <Select<SupportedLlm> disabled={!categoryModels?.length} onChange={model => handleModelSelection({
                model,
                category,
                isDirectCategorySelect: false
              })} options={categoryModels.filter(model => orgAvailableModels.includes(model)).map((model: SupportedLlm) => ({
                value: model,
                label: LLM_DISPLAY_NAMES[model],
                suffix: {
                  text: COST_TIER_INDICATOR_TEXT[MODEL_COST_TIERS[model]],
                  color: TextIconColor.SECONDARY,
                  size: TypographySize.X_SMALL
                }
              }))} placeholderLabel="Models" value={CATEGORY_SELECTED_MODEL[category]} />
                  </SelectWrapper>
                </CategoryCardContainer>;
        })}
        </CategoryContainer>
        <BottomContainer>
          <Select<SupportedLlm> onChange={model => handleModelSelection({
          model
        })} options={orgAvailableModels.map(model => ({
          value: model,
          label: LLM_DISPLAY_NAMES[model],
          suffix: {
            text: COST_TIER_INDICATOR_TEXT[MODEL_COST_TIERS[model]],
            color: TextIconColor.SECONDARY,
            size: TypographySize.X_SMALL
          }
        }))} placeholderLabel="All Models" prefix="All Models - Current: " value={chatModel} />
          {withLink(<LinkContainer>
              <Typography casing={TypographyCasing.UPPERCASE} font={TypographyFont.INTERACTIVE}>
                More about our models
              </Typography>
              <Icons color={TextIconColor.PRIMARY} icon={Icon.LINK} />
            </LinkContainer>, {
          href: '/settings/' + SETTINGS_TAB_ROUTES[SettingsTab.MODELS],
          newTab: true
        })}
        </BottomContainer>
      </Modal>
    </>;
};
export default ModelSelectionModal;