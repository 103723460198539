import _styled from "styled-components";
import { ContentListSort } from '@kindo/api';
import { INTEGRATION_DISPLAY_NAMES, isAudioFileType, isIntegration, isVideoFileType } from '@kindo/universal';
import { formatDistanceToNowStrict } from 'date-fns';
import { Progress } from '../../Progress';
import { FileResource } from './AddFileModal.types';
import { FileUploadStatus } from './FileUploader';
import { TextIconColor, Icon, IconButton, IconButtonType, TableColumn, TableRow, Typography, TypographyWeight, TypographySize, TypographyFont } from '~/components/core';
import { StyledTableRow } from '~/components/core/Table/Table.styles';
import { ContentIcon } from '~/components/shared';
import { getContentDirectFetchUrl } from '~/constants';
export type FileResourceWithTableMetadata = FileResource & {
  hasPlaintextTranscription: boolean;
  isSupportedByModel: boolean;
  isTooLargeToDownloadFromMerge: boolean;
  isUploadedToCorrespondingCloud: boolean;
  llamaIndexedAt: string | null;
};
export const shouldShowFileTranscriptionDownload = (file: FileResourceWithTableMetadata): {
  isTranscriptionReady: boolean;
  shouldShowTranscriptionDownload: boolean;
} => {
  const shouldShowTranscriptionDownload = !!file.fileType && (isVideoFileType(file.fileType) || isAudioFileType(file.fileType));
  return {
    shouldShowTranscriptionDownload,
    isTranscriptionReady: file.hasPlaintextTranscription
  };
};
const ellipsis = (text: string, max = 35) => {
  if (text.length > max) {
    return text.substring(0, 20) + '...' + text.substring(text.length - 10, text.length);
  }
  return text;
};
export const getFileTableColumns = (hasAnyTranscriptions: boolean): TableColumn<ContentListSort>[] => [{
  title: 'NAME',
  width: {
    "width": "50%"
  },
  sortKey: ContentListSort.FILE_NAME
}, {
  title: 'TYPE',
  width: {
    "width": "8.333333%"
  },
  sortKey: ContentListSort.FILE_TYPE
}, {
  title: 'SOURCE',
  width: {
    "width": "12.5%"
  },
  // 1/8
  sortKey: ContentListSort.INTEGRATION
}, {
  title: 'UPLOADED',
  width: {
    "width": "12.5%"
  },
  // 1/8
  sortKey: ContentListSort.CREATED_AT
}, ...(hasAnyTranscriptions ? [{
  title: '',
  // Download transcription
  width: {
    "width": "8.333333%"
  }
}] : []), {
  title: '',
  // Remove file
  width: {
    "width": "8.333333%"
  }
}];
export const getFileUploadRow = (upload: FileUploadStatus, hasAnyTranscriptions: boolean): React.ReactElement => <StyledTableRow key={upload.file.name} $clickable={false} $disabled={false} $height={{
  "height": "3.5rem"
}} title={`Uploading ${upload.file.name} ${upload.progress}%`}>
    <td />
    <td css={{
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontFamily": "var(--font-ibmPlexMono)",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgb(255 255 255 / var(--tw-text-opacity))"
  }}>
      <span>{ellipsis(upload.file.name)}</span>
    </td>
    <td css={{
    "padding": "0.5rem"
  }} colSpan={getFileTableColumns(hasAnyTranscriptions).length}>
      <Progress value={upload.progress} />
    </td>
  </StyledTableRow>;
const FileSourceContainer = _styled.div`
  ${{
  "display": "flex",
  "alignItems": "center",
  "gap": "0.25rem"
}}
`;

// Determine the tooltip message and text color based on file status
const getFileTooltipAndColor = (file: FileResourceWithTableMetadata, isKnowledgeStore: boolean) => {
  // If the file is both "not supported by the model" and "not ready for use",
  // prioritize the "not supported by the model" status for visual indication
  if (!file.isSupportedByModel) {
    return {
      disabled: true,
      toolTip: 'File type not supported by the model',
      disabledTextColor: TextIconColor.SECONDARY
    };
  }
  if (!file.isUploadedToCorrespondingCloud) {
    if (file.isTooLargeToDownloadFromMerge) {
      return {
        disabled: true,
        toolTip: 'File is too large to download',
        disabledTextColor: TextIconColor.DISABLED
      };
    }
    return {
      disabled: true,
      toolTip: 'File not yet ready for use',
      disabledTextColor: TextIconColor.DISABLED
    };
  }
  if (file.llamaIndexedAt === null && isKnowledgeStore) {
    return {
      disabled: true,
      toolTip: 'File has not been processed yet',
      disabledTextColor: TextIconColor.DISABLED
    };
  }
  return {
    disabled: false
  };
};
export const getFileRow = (file: FileResourceWithTableMetadata, onRemoveFile: (fileId: string) => void, hasAnyTranscriptions: boolean, isSelected: boolean, isKnowledgeStore: boolean): TableRow => {
  const {
    shouldShowTranscriptionDownload,
    isTranscriptionReady
  } = shouldShowFileTranscriptionDownload(file);
  const {
    toolTip,
    disabledTextColor,
    disabled
  } = getFileTooltipAndColor(file, isKnowledgeStore);
  const source = isIntegration(file.source) ? INTEGRATION_DISPLAY_NAMES[file.source] : file.source;

  // Handle transcription download button
  const transcriptionButton = () => {
    if (!hasAnyTranscriptions) return [];
    if (!shouldShowTranscriptionDownload) return [undefined];
    return [<IconButton key={`download-transcription-${file.id}`} disabled={!isTranscriptionReady} href={getContentDirectFetchUrl({
      contentId: file.id,
      isDownload: true,
      isPlaintext: true
    })} icon={Icon.EDIT_FILE} title="Download Transcription" type={IconButtonType.OUTLINED} />];
  };
  return {
    disabled,
    disabledTextColor,
    toolTip,
    key: file.id,
    height: {
      "height": "3.5rem"
    },
    cells: [<Typography key={`${file.id}-name`} color={isSelected ? TextIconColor.HIGHLIGHT : TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.SMALL} weight={TypographyWeight.NORMAL}>
        {file.name}
      </Typography>, file.fileType,
    // Source
    <FileSourceContainer key={file.id}>
        <ContentIcon contentSource={file.source} />
        <Typography color={disabledTextColor} font={TypographyFont.HEADING} size={TypographySize.SMALL} weight={TypographyWeight.NORMAL}>
          {source}
        </Typography>
      </FileSourceContainer>,
    // Uploaded (createdAt)
    <Typography key={`uploaded-${file.id}`} size={TypographySize.SMALL} weight={TypographyWeight.NORMAL}>
        {formatDistanceToNowStrict(new Date(file.createdAt), {
        addSuffix: true
      })}
      </Typography>, ...transcriptionButton(), <IconButton key={`remove-${file.id}`} icon={Icon.TRASH} onClick={() => onRemoveFile(file.id)} title="Remove File" type={IconButtonType.NONE} />]
  };
};