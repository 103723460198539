import _styled from "styled-components";
const ProgressContainer = _styled.div({
  "height": "0.25rem",
  "width": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(68 68 96 / var(--tw-bg-opacity))"
});
const ProgressBar = _styled.div({
  "height": "0.25rem",
  "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#320DC4 var(--tw-gradient-from-position)",
  "--tw-gradient-to": "#8544D9 var(--tw-gradient-to-position)",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)"
});
type ProgressProps = {
  value: number;
};
export const Progress: React.FC<ProgressProps> = ({
  value
}) => <ProgressContainer>
    <ProgressBar css={{
    width: `${Math.min(value, 100)}%`
  }} />
  </ProgressContainer>;