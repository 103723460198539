import _styled from "styled-components";
import { TextIconColor } from '../constants/colors.consts';
import { Typography } from '../Typography';
import { TypographyCasing, TypographyFont, TypographySize, TypographyWeight } from '../Typography/Typography.consts';
import { BadgeColor, BadgeType } from './Badge.consts';
import { BADGE_COLORS } from './Badge.styles';
const StyledBadge = _styled.div<{
  $active: boolean;
  $color: BadgeColor;
  $semiRounded: boolean;
  $type: BadgeType;
}>(({
  $color,
  $type,
  $semiRounded,
  $active
}) => [{
  "display": "inline-flex",
  "width": "fit-content",
  "alignItems": "center",
  "borderRadius": "0.125rem",
  "paddingLeft": "0.5rem",
  "paddingRight": "0.5rem",
  "paddingTop": "0.125rem",
  "paddingBottom": "0.125rem"
}, $type === BadgeType.SOLID && $active && BADGE_COLORS[$color].bg, $type === BadgeType.OUTLINE && [BADGE_COLORS[$color].border, {
  "borderWidth": "1px",
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}], $semiRounded && {
  "borderBottomRightRadius": "0px",
  "borderBottomLeftRadius": "0px",
  "borderTopLeftRadius": "3px",
  "borderTopRightRadius": "3px"
}, !$active && {
  "backgroundColor": "rgb(255 255 255 / 0.1)"
}]);
interface BadgeProps {
  label: string;
  active?: boolean;
  color?: BadgeColor;
  semiRounded?: boolean;
  type?: BadgeType;
}
const Badge = ({
  label,
  type = BadgeType.SOLID,
  semiRounded = false,
  active = true,
  color = BadgeColor.DEFAULT
}: BadgeProps) => <StyledBadge $active={active} $color={color} $semiRounded={semiRounded} $type={type}>
    <Typography casing={TypographyCasing.UPPERCASE} color={color === BadgeColor.WARNING && type === BadgeType.SOLID ? TextIconColor.BLACK : TextIconColor.PRIMARY} font={TypographyFont.HEADING} size={TypographySize.X_SMALL} weight={TypographyWeight.MEDIUM}>
      {label}
    </Typography>
  </StyledBadge>;
export default Badge;