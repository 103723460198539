import { BadgeColor } from './Badge.consts';
export const BADGE_COLORS: Record<BadgeColor, {
  bg: TwStyle;
  border: TwStyle;
}> = {
  [BadgeColor.ERROR]: {
    border: {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(255 11 80 / var(--tw-border-opacity))"
    },
    bg: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(255 11 80 / var(--tw-bg-opacity))"
    }
  },
  [BadgeColor.HIGHLIGHT]: {
    border: {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(84 254 209 / var(--tw-border-opacity))"
    },
    bg: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(84 254 209 / var(--tw-bg-opacity))"
    }
  },
  [BadgeColor.PRIMARY_HIGHLIGHT]: {
    border: {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(141 118 255 / var(--tw-border-opacity))"
    },
    bg: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(141 118 255 / var(--tw-bg-opacity))"
    }
  },
  [BadgeColor.WARNING]: {
    border: {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(253 235 144 / var(--tw-border-opacity))"
    },
    bg: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(253 235 144 / var(--tw-bg-opacity))"
    }
  },
  [BadgeColor.DEFAULT]: {
    border: {
      "borderColor": "rgb(255 255 255 / 0.2)"
    },
    bg: {
      "backgroundColor": "rgb(255 255 255 / 0.2)"
    }
  },
  [BadgeColor.PRIMARY_BRIGHT]: {
    border: {
      "--tw-border-opacity": "1",
      "borderColor": "rgb(72 38 253 / var(--tw-border-opacity))"
    },
    bg: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgb(72 38 253 / var(--tw-bg-opacity))"
    }
  }
};