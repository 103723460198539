import _styled from "styled-components";
import { ContentListSort } from '@kindo/api';
import { FileType, Llm, MERGE_DOWNLOAD_FILE_SIZE_LIMIT, getModelSupportedFileTypes, isGeminiChatLlm, isMergeIntegration } from '@kindo/universal';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ContentTableSort, DEFAULT_CONTENT_FILTER, DEFAULT_CONTENT_MODAL_SORT, DEFAULT_LIMIT } from './AddFileModal.consts';
import { FileResource } from './AddFileModal.types';
import { uploadFile } from './AddFileModal.utils';
import { FileResourceWithTableMetadata, getFileRow, getFileTableColumns, getFileUploadRow, shouldShowFileTranscriptionDownload } from './AddFileModalComponentUtils';
import { FileUploader, useFileUploads } from './FileUploader';
import { FilterFilesForm } from './FilterFilesForm';
import { Pagination } from './Pagination';
import { Button, ButtonType, Modal, ModalWidth, Table, Typography, TypographyFont, TypographySize, TypographyWeight } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ContentFiltersValues } from '~/components/shared/Filters';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc, trpc } from '~/trpc';
import { ContentSource } from '~/types';
const ConfirmationContainer = _styled.div({
  "marginLeft": "auto",
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem"
});
const FooterContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "space-between",
  "paddingTop": "1.5rem",
  "paddingBottom": "1.5rem"
});
const AddFileContainer = _styled.div({
  "position": "relative",
  "display": "flex",
  "flexDirection": "column",
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem"
});
export type ContentItem = Awaited<ReturnType<(typeof trpc)['content']['list']['query']>>['items'][number];
const convertContentItemToFileResource = (contentItem: ContentItem, activeModel: Llm, modelSupportedFileTypes: FileType[]): FileResourceWithTableMetadata => ({
  id: contentItem.id,
  name: decodeURIComponent(contentItem.filename),
  fileType: contentItem.fileType,
  source: contentItem.integrationName as ContentSource,
  createdAt: contentItem.createdAt,
  hasPlaintextTranscription: !!contentItem.plaintextUrn,
  // For Gemini, an file cannot be used until it has been uploaded to GCS
  isUploadedToCorrespondingCloud: isGeminiChatLlm(activeModel) ? !!contentItem.gcsUri : !!contentItem.s3Key,
  isSupportedByModel: contentItem.fileType !== undefined && modelSupportedFileTypes.includes(contentItem.fileType),
  isTooLargeToDownloadFromMerge: !contentItem.s3Key && isMergeIntegration(contentItem.integrationName as any) && contentItem.sizeBytes !== null && contentItem.sizeBytes > MERGE_DOWNLOAD_FILE_SIZE_LIMIT,
  llamaIndexedAt: contentItem.llamaIndexedAt
});
type AddFileModalProps = Prettify<{
  activeModel: Llm;
  initialSelectedFiles: FileResource[];
  onClose: () => void;
  onSelect: (files: FileResource[]) => void;
  // Only select one file at a time
  className?: string;
  isKnowledgeStore?: boolean;
  selectedFiles?: FileResource[];
  singleSelection?: boolean;
}>;
export const AddFileModal = ({
  isKnowledgeStore = false,
  onSelect,
  onClose,
  initialSelectedFiles,
  singleSelection = false,
  activeModel
}: AddFileModalProps) => {
  // State
  const [fileToRemove, setFileToRemove] = useState<FileResourceWithTableMetadata | null>(null);
  const [filter, setFilter] = useState<ContentFiltersValues>(DEFAULT_CONTENT_FILTER);
  const [sort, setSort] = useState<ContentTableSort>(DEFAULT_CONTENT_MODAL_SORT);
  const [selected, setSelected] = useState<FileResource[]>(initialSelectedFiles);
  const [page, setPage] = useState(0);
  const [shouldPollData, setShouldPollData] = useState<boolean>(false);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();

  // Ref
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const limit = DEFAULT_LIMIT;
  const acceptedFileTypes: FileType[] = getModelSupportedFileTypes(activeModel);
  const {
    data,
    refetch: refetchUploadedFiles,
    isLoading
  } = nextTrpc.content.list.useQuery({
    limit,
    skip: page * limit,
    filter: {
      textSearch: filter.name,
      fileTypes: filter.type ? [filter.type as FileType] : undefined
    },
    sort: sort.key,
    sortDesc: sort.direction === 'desc'
  }, {
    refetchInterval: shouldPollData ? 3000 : false,
    select: newData => {
      const itemsAsFiles = newData?.items?.map(item => convertContentItemToFileResource(item, activeModel, acceptedFileTypes));
      return {
        ...newData,
        items: itemsAsFiles
      };
    },
    refetchOnWindowFocus: false
  });

  // Mutations
  const removeContentMutation = nextTrpc.content.remove.useMutation({
    onSuccess: () => {
      setFileToRemove(null);
      refetchUploadedFiles();
      enqueueToast({
        message: 'File removed successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: err => {
      console.error(err);
      enqueueToast({
        message: 'Failed to remove file',
        type: ToastType.ERROR
      });
    }
  });

  // Create a debounced version of the refetchUploadedFiles function
  // The debounce function delays the execution of refetchUploadedFiles by 500ms
  // This helps to reduce the number of API requests made while the user is typing
  // And stops refresh jank when the user is typing
  const debouncedRefetchUploadedFiles = () => debounce(refetchUploadedFiles, 500);

  // Wrap in useMemo to avoid infinite re-renders in useEffect
  const files: FileResourceWithTableMetadata[] = useMemo(() => data?.items ?? [], [data?.items]);
  const selectedButNotOnCurrentPage = useMemo(() => selected.filter(s => !files.map(f => f.id).includes(s.id)),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [files.length, selected.length]);

  // Check for unprocessed files
  useEffect(() => {
    if (files.some(file => {
      const {
        shouldShowTranscriptionDownload,
        isTranscriptionReady
      } = shouldShowFileTranscriptionDownload(file);
      return file.isUploadedToCorrespondingCloud || shouldShowTranscriptionDownload && !isTranscriptionReady;
    })) {
      setShouldPollData(true);
    } else {
      setShouldPollData(false);
    }
  }, [files]);
  const {
    uploads,
    addUploads
  } = useFileUploads({
    uploadFile: async (file, onProgress) => await uploadFile(file, onProgress),
    onUploadComplete: () => {
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      void refetchUploadedFiles();
    }
  });
  const handleRemoveFile = (fileId: string) => {
    const file = files.find(f => f.id === fileId);
    setFileToRemove(file || null);
  };
  const handleConfirmDelete = (contentId: string) => removeContentMutation.mutate({
    id: contentId
  });
  const hasAnyFilesWithTranscription = files.some(file => shouldShowFileTranscriptionDownload(file).shouldShowTranscriptionDownload);
  return <Modal noContentPadding onClose={() => onClose()} open width={ModalWidth.NINE_TWENTY}>
      <AddFileContainer>
        <Typography font={TypographyFont.HEADING} size={TypographySize.H4} weight={TypographyWeight.MEDIUM}>
          File Library
        </Typography>
        <FilterFilesForm filter={filter} onDeselectAll={() => setSelected([])} onUploadClick={() => {
        inputFileRef.current?.click();
      }} refetchUploadedFiles={debouncedRefetchUploadedFiles} selectedFileCount={selected.length} setFilter={filters => {
        setFilter(filters);
        setPage(0);
      }} />
        <FileUploader acceptedFileTypes={acceptedFileTypes} css={{
        "height": "100%"
      }} onChange={addUploads} onLoad={ref => {
        inputFileRef.current = ref.current;
      }} />
        <Table<string, string, ContentListSort> columns={getFileTableColumns(hasAnyFilesWithTranscription)} noRowsText={isLoading ? 'Loading...' : 'No files found'} rows={[...uploads.map(upload => getFileUploadRow(upload, hasAnyFilesWithTranscription)), ...files.map(file => getFileRow(file, handleRemoveFile, hasAnyFilesWithTranscription, selected.some(s => s.id === file.id), isKnowledgeStore))]} selectionProps={{
        selectedItems: selected.map(({
          id
        }) => id),
        setSelectedItems: newSelected => {
          setSelected([...selectedButNotOnCurrentPage, ...files.filter(({
            id
          }) => newSelected.includes(id))]);
        },
        singleSelection
      }} showColumnHeaderBorder sortProps={{
        activeSort: sort,
        setActiveSort: value => setSort(value)
      }} />
        <FooterContainer>
          <Pagination initialPage={page} onPageActive={() => {}} onPageChange={setPage} totalPages={data?.totalPages || 1} />
          <ConfirmationContainer>
            <Button label="Cancel" onClick={() => onClose?.()} type={ButtonType.OUTLINED} />
            <Button disabled={!selected.length} label="Select File" onClick={() => {
            onSelect?.(selected);
            onClose?.();
          }} type={!selected.length ? ButtonType.OUTLINED : ButtonType.SOLID_GRADIENT} />
          </ConfirmationContainer>
        </FooterContainer>
      </AddFileContainer>
      {fileToRemove && <ConfirmationModal confirmButtonLabel="Remove" confirmLoading={false} header={fileToRemove ? `Are you sure you want to remove ${fileToRemove.name} from Kindo?` : ''} onCancel={() => setFileToRemove(null)} onConfirm={() => handleConfirmDelete(fileToRemove.id)} open={!!fileToRemove} subtext="This action cannot be undone." />}
    </Modal>;
};
export default AddFileModal;